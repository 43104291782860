import {request,} from '@/common/requestZt.js'
import {ztUrl} from '@/common/const.js'

/* 品牌  */
console.log();
// 品牌列表 无分页
export function getPag(data) {
    return request.get(ztUrl + '/product/brand/list',
        data, localStorage.getItem('middle-token'))
}
