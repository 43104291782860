/*
    商品地址接口
 */

import {request,} from '@/common/requestZt.js'
import {ztUrl} from '@/common/const.js'


// 生成商品编码
function getCode(data) {
    return request.get(ztUrl + '/product/product/getCode',
        data, localStorage.getItem('middle-token'))
}
// 商品新增、编辑
function ProduAddandEdit(data) {
    return request.post(ztUrl + '/product/product/edit',
        data, localStorage.getItem('middle-token'))
}
// 商品详情
function Detail(data) {
    return request.get(ztUrl + '/product/product/detail',
        data, localStorage.getItem('middle-token'))
}
// 从链接中抓取数据
function grapLinks(data) {
    return request.get(ztUrl + '/product/product/grapLinks',
        data, localStorage.getItem('middle-token'))
}
//商品属性名称列表
function getspec(data) {
    return request.get(ztUrl + '/product/spec/list',
        data, localStorage.getItem('middle-token'))
}


export {
    getCode,
    Detail,
    ProduAddandEdit,
    grapLinks,
    getspec,
}
