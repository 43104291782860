<template>
    <div class="uploadWrapper">

        <vuedraggable class="vue-draggable" :class="{ single: isSingle, maxHidden: isMaxHidden }" v-model="imgList" animation="500" tag="ul" draggable=".draggable-item" @start="onDragStart" @end="onDragEnd">
            <!-- 拖拽元素 -->
            <li v-for="(item, index) in imgList" :key="item + index" class="draggable-item" :style="{ width: width + 'px', height: height + 'px' }">
                <el-image :src="item" :preview-src-list="[item]"></el-image>
                <div class="shadow" @click="onRemoveHandler(index)">
                    <i class="el-icon-delete"></i>
                </div>
                <span class="main" v-show="(index===0)">主图</span>
            </li>

            <div v-show="isMaxHidden">
                <el-upload :action="uploadImgUrl" list-type="picture-card" :on-success="handleUploadSuccesshub" :before-upload="validImgUpload" :on-error="handleUploadErrorhub" multiple name="file" :show-file-list="false" :headers="headers">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </div>

            <!-- <el-button>选择图片</el-button> -->

        </vuedraggable>
    </div>
</template>
<script>
import vuedraggable from 'vuedraggable'
// import { getToken } from "@/utils/auth";
import {IMAGE_UPLOAD_OSS_URL, ztUrl} from '@/common/const.js'
export default {
    name: 'Imglist',
    components: {
        vuedraggable
    },
    props: {

        imgArr: {
            type: Array,
            default: [],
        },

        // 限制上传的图片数量
        limit: {
            type: Number,
            default: 9
        },
        // 限制上传图片的文件大小(kb)
        size: {
            type: Number,
            default: 1024
        },
        // 是否是单图上传(单图上传就是已传图片和上传按钮重叠)
        isSingle: {
            type: Boolean,
            default: false
        },
        // 是否显示选择图片
        ImgVisible: {
            type: Boolean,
            default: false
        },
        // 是否使用图片压缩
        useCompress: {
            type: Boolean,
            default: false
        },
        // 图片显示的宽度(px)
        width: {
            type: Number,
            default: 100
        },
        // 图片显示的高度(px)
        height: {
            type: Number,
            default: 100
        }
    },

    data() {
        return {
            imgList: [],
            draimgList: [],
            uploadImgUrl: IMAGE_UPLOAD_OSS_URL, // 上传的图片服务器地址
            headers: {
                Authorization: localStorage.getItem('middle-token'),
            },
        }
    },

    watch: {
        // 接收父组件传过来的图片
        imgArr(val) {
            this.imgList = val;
        },

        // 监听图片顺序发生改变
        imgList(val) {
            // console.log(val, '图片顺序改变')
            this.$emit('update:imgArr', val)
        }

    },
    computed: {
        // 控制达到最大限制时隐藏上传按钮
        isMaxHidden() {
            if (this.imgList.length == 0) {
                return true
            } else {
                return this.imgList.length < this.limit
            }
        }
    },

    methods: {
        validImgUpload(file, size) {
            // size = +size || 10240
            const isSizeOut = file.size / 1024 > this.size
            if (isSizeOut) {
                this.msgError('上传图片大小不能超过1M')
            } else {
                // this.loading = this.$loading({
                //     lock: true,
                //     text: "上传中",
                //     background: "rgba(0, 0, 0, 0.7)",
                // });
            }
            return !isSizeOut
        },

        // createUniqueString() {
        //     const timestamp = +new Date() + ''
        //     const randomNum = parseInt((1 + Math.random()) * 65536) + ''
        //     return (+(randomNum + timestamp)).toString(32)
        // },

        // 移除单张图片
        onRemoveHandler(index) {

            this.$confirm('确定删除该图片?').then(() => {

                this.imgList.splice(index, 1);

                this.$emit('update:imgArr', this.imgList)

            }).catch(() => {
                return false
            });
        },
        onDragStart(e) {
            // console.log("拖拽开始")
            // e.target.classList.add('hideShadow')
        },
        onDragEnd(e) {
            // console.log(this.imgList)

            // console.log('拖拽结束', e, this.imgArr)

            // this.$emit("pictureorder", this.imgArr);
            // e.target.classList.remove('hideShadow')
        },

        handleUploadSuccesshub(res) {

            // console.log(res.data[0].url)
            // console.log(this.imglist, 'imglist的值')
            // console.log(this.imgArr, 'imgArr的值')

            this.imgList.push(res.data[0].url);


            // this.loading.close();
            // this.msgSuccess('上传成功');
        },
        handleBeforeUploadhub() {
        },
        handleUploadErrorhub() {
            this.$message({
                type: "error",
                message: "上传失败",
            });
            this.loading.close();
        },

    },
}
</script>
<style scoped>
.uploadWrapper >>> .el-upload {
    width: 100px;
    height: 100px;
    line-height: 100px;
}

/* // 上传按钮 */
.uploadWrapper >>> .uploadIcon {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #c0ccda;
    background-color: #fbfdff;
    border-radius: 6px;
    font-size: 20px;
    color: #999;
}

.uploadWrapper >>> .uploadIcon .limitTxt.uploading {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    font-size: 12px;
    text-align: center;
}

.main {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50px;
    height: 20px;
    background-color: orange;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    border-radius: 0 20px 0 0;
}

/* // 拖拽 */
.uploadWrapper >>> .vue-draggable {
    display: flex;
    flex-wrap: wrap;
}

.uploadWrapper >>> .vue-draggable .draggable-item {
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
}

.uploadWrapper >>> .vue-draggable .draggable-item .el-image {
    width: 100%;
    height: 100%;
}

.uploadWrapper >>> .vue-draggable .draggable-item .shadow {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    padding: 2px;
    cursor: pointer;
}

.uploadWrapper >>> .vue-draggable .draggable-item:hover .shadow {
    opacity: 1;
}

.uploadWrapper >>> .hideShadow .shadow {
    display: none;
}

.uploadWrapper >>> .single {
    overflow: hidden;
    position: relative;
}

.uploadWrapper >>> .single .draggable-item {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.uploadWrapper >>> .maxHidden .uploadBox {
    display: none;
}

/* el-image */
.uploadWrapper >>> .el-image-viewer__wrapper .el-image-viewer__mask {
    opacity: 0.8;
}

.uploadWrapper >>> .el-image-viewer__wrapper .el-image-viewer__mask .el-icon-circle-close {
    color: #fff;
}
</style>
