/*
 * @Author: your name
 * @Date: 2020-06-29 16:37:51
 * @LastEditTime: 2020-06-29 16:40:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \b2b2c-admin\src\common\request.js
 */


import axios from 'axios';
import Router from '../router/index.js';
axios.defaults.withCredentials = false
let qs = require('qs');
let isdebug = 1 // 1 本地  2 测试   3  正式
// let path = isdebug == 1 ? '/i/admin' : isdebug == 2 ? 'http://admin.lanmons.com/i/admin' : isdebug == 3 ? 'http://admin.xyk1688.com/i/admin' : '';
let path = isdebug == 1 ? '/i/admin' : isdebug == 2 ? 'http://admin.lanmons.com/i/admin' : isdebug == 3 ? 'http://azapi.xingpinmao.com/i' : '';
// let path = 'http://test-hsz.xingpinmao.com';


axios.interceptors.request.use(
    config => {
        if (localStorage.getItem('token')) {
            config.headers.token = localStorage.getItem('token');
        }
        config.headers.platform = 'admin';
        return config;
    },
    error => {
        return Promise.reject(error);
    });

export const request = {
    get: function(url, params, token) {
        url = token ? url : path + url;
        if (!params) {
            params = {};
        }
        return axios.get(url, {
            params: params,
            headers: {
                "Authorization": token,
                "Content-Type": token ? 'application/json;charset=utf-8' : 'application/json'
            }
        }).then(function(ret) {
            if (ret.data.code == 997) {
                localStorage.removeItem('token');
                localStorage.removeItem('ms_username');
                Router.push('/login');
                return;
            }
            return ret.data;
        });
    },
    post: function(url, params, token) {
        url = token ? url : path + url;
        return axios.post(url, token ? params : qs.stringify(params), {
            headers: {
                "Authorization": token,
                "Content-Type": token ? 'application/json;charset=utf-8' : 'application/json'
            }
        }).then(function(ret) {
            if (ret.data.code == 997) {
                localStorage.removeItem('token');
                localStorage.removeItem('ms_username');
                Router.push('/login');
                return;
            }
            return ret.data;
        });
    },
    postJson: function(url, params) {
        url = path + url;
        var header = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        return axios.post(url, JSON.stringify(params), {
            headers: header
        }).then(function(ret) {
            return ret.data;
        });
    },
    delete: function(url) {
        url = path + url;
        return axios.delete(url).then(function(ret) {
            return ret.data;
        });
    }
};



