<template>
    <div class="skuupdiv">

        <el-upload :action="uploadImgUrl" list-type="picture-card" :limit="limitsku" id='up' :on-success="handleUploadSuccesssku" :before-upload="validImgUploadsku" :on-error="handleUploadErrorhubsku" :headers="headers" :on-remove='removusku' :on-exceed='exceedsku' :file-list="fileList">
            <!-- <slot></slot> -->
            <i class="el-icon-plus"></i>

        </el-upload>
    </div>
</template>

<script>
// import { getToken } from "@/utils/auth";
import {IMAGE_UPLOAD_OSS_URL, ztUrl} from '@/common/const.js'
export default {
    name: 'Skuimgupload',
    props: {
        // 限制上传的图片数量
        skulimit: {
            type: Number,
            default: 1
        },
        keyindex: {
            type: Number,
            default: 0
        },
        limitsku: {
            type: Number,
            default: 1,
        },
        skuimg: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            uploadImgUrl: IMAGE_UPLOAD_OSS_URL, // 上传的图片服务器地址
            headers: {
                Authorization: localStorage.getItem('middle-token'),
            },
            skuloading: null,
            fileList: [],
        }
    },

    created() {
        if (this.skuimg != '') {
            let arr = [{
                url: this.skuimg
            }]
            this.fileList = arr;
        }
    },

    // watch: {
    //     skuimg(val) {
    //         console.log(val, '图片数据');
    //     }
    // },

    methods: {

        // 上传成功
        handleUploadSuccesssku(res) {

            this.$message({
                type: "success",
                message: "上传成功",
            });
            // this.skuimg = res.data[0].url;

            this.$emit('update', res.data[0].url)

            this.$emit('input', res.data[0].url);

            this.skuloading.close();

            console.log(res.data[0].url)
        },

        // 上传前
        validImgUploadsku() {
            this.skuloading = this.$loading({
                lock: true,
                text: "上传中",
                background: "rgba(0, 0, 0, 0.7)",
            });

        },

        // 上传失败
        handleUploadErrorhubsku() {
            this.$message({
                type: "error",
                message: "上传失败",
            });
            this.skuloading.close();
        },

        // 超出个数
        exceedsku() {
            this.$message({
                type: "error",
                message: "只能上传一张图片",
            });
        },

        // 移除图片
        removusku(res) {
            // v-model同步
            this.$emit('input', '');
        }

    },
}
</script>

