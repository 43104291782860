<template>
    <!-- 富文本编辑器,支持多图批量上传 -->
    <div>
        <quill-editor class="editor" ref="myTextEditor" v-model="content" :options="editorOption"></quill-editor>
        <el-upload ref="imageListUpload" class="select-uploader" hidden multiple :action="uploadImgUrl" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="headers">
            上传图片
        </el-upload>
    </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor, Quill } from 'vue-quill-editor';
import {IMAGE_UPLOAD_OSS_URL} from '@/common/const.js'
// import { getToken } from '@/utils/auth'




let BlockEmbed = Quill.import('blots/block/embed');
const Link = Quill.import('formats/link')
class ImageBlot extends BlockEmbed {
    static create(value) {
        let node = super.create();
        node.setAttribute('style', value.style);
        node.setAttribute('src', value.url);
        return node;
    }
    static value(node) {
        return {
            alt: node.getAttribute('style'),
            url: node.getAttribute('src')
        };
    }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';

Quill.register(ImageBlot, true) // 插入图片

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['image', 'video'],
    ['clean']                                         // remove formatting button
]

export default {
    name: 'quellEditor',
    components: {
        quillEditor
    },

    props: {
        detailContent: {
            type: String,
            default: '',
        }
    },

    data() {
        return {

            content: '',

            uploadImgUrl: IMAGE_UPLOAD_OSS_URL, // 上传的图片服务器地址
            headers: {
                Authorization: localStorage.getItem('middle-token'),
            },

            editorOption: {
                placeholder: '请输入',
                content: this.content,
                modules: {
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                        handlers: {
                            'image': function(value) {
                                if (value) {
                                    // document.querySelector('#detail-img').click()
                                    document.querySelector('.select-uploader input').click();
                                } else {
                                    this.quill.format('image', false)
                                }
                            }
                        }
                    }
                }
            },

        }
    },

    watch: {
        content(val) {
            this.$emit('update:detailContent', val)
        },

        detailContent(val) {
            this.content = val;
        }

    },

    methods: {

        //图片上传
        handleAvatarSuccess(res, file, fileList) {
            let len = fileList.length;
            let successCount = 0;
            for (let file of fileList) {
                if (file.status === 'success') {
                    successCount += 1;
                } else {
                    break;
                }
            }

            if (len === successCount) {
                this.$refs.imageListUpload && this.$refs.imageListUpload.clearFiles()
                for (let file of fileList) {
                    // 获取富文本组件实例
                    let quill = this.$refs.myTextEditor.quill;
                    // 获取光标所在位置
                    let length = quill.getSelection().index
                    // quill.insertEmbed(length, 'image', res.data.uri)
                    quill.insertEmbed(length, 'image', {
                        style: 'display:block;',
                        url: file.response.data.uri
                    })
                    // 调整光标到最后
                    quill.setSelection(length + 1)
                }
            }
        },

        beforeAvatarUpload(file) {
            // loads.open('.avatar-uploader .el-upload', '上传中')
            const _this = this
            return this.imageFormat(file, _this, 300)
        },

        getDetailImg(urlList) {
            // 获取富文本组件实例
            let quill = this.$refs.myTextEditor.quill
            for (let url of urlList) {
                // 获取光标所在位置
                let length = quill.getSelection().index

                // 插入图片  res.info为服务器返回的图片地址
                quill.insertEmbed(length, 'image', url)

                // 调整光标到最后
                quill.setSelection(length + 1)
            }

            this.detailImgVisible = false;
        },

        imageFormat(file, _this, size = 300) {
            console.log(size)
            console.log(3333, file.type)
            const isIMG = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt = file.size / 1024 <= size
            if (!isIMG) {
                _this.$message.error('上传图片只支持jpg、jpeg、png格式!')
                return false
            }
            /*if (!isLt) {
                _this.$message.error(`上传图片大小不能超过${size}KB!`)
                return false
            }*/
        },
    },

}
</script>

<style  scoped>
/* 文本编辑样式 */
.editor {
    width: 60%;
    height: 600px;
}
.editor >>> .ql-container {
    min-height: 300px;
}
.ql-snow .ql-editor img {
    display: block !important;
}
</style>
