<template>
    <!-- 商品发布 -->
    <div class="handwork">
        <p class="disply"><span class="span"></span><span>发布方式</span></p>
        <p class="disply" style="margin-bottom: 0px;">

            <el-radio-group v-model="ruleForm.product.sourcePlatformId" :disabled='produId!=null'>
                <el-radio :label="0">手动填写</el-radio>
                <el-radio :label="1">抓链接数据</el-radio>
                <el-radio :label="2">本地导入</el-radio>
            </el-radio-group>
        </p>
        <br />
        <p style="margin-top: 0px;" v-if="ruleForm.product.sourcePlatformId==0">*请按以下要求字段上传商品资料，星号为必填项</p>

        <div v-if="ruleForm.product.sourcePlatformId==1">
            <el-form>
                <el-form-item label="宝贝链接" prop="boylink">
                    <el-input type="text" placeholder="请粘贴要抓取的宝贝链接，支持淘宝、天猫、京东" style="width:40%;" v-model="Babylink" show-word-limit clearable>
                    </el-input>
                    <el-button type="primary" style="margin-left:10px;" @click="grablink">开始</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div v-if="ruleForm.product.sourcePlatformId==2">
            <el-form :rules="{ impdao: [ { required: true, message: '此项不能为空', trigger: 'blur' }]}">
                <el-form-item label="商品数据" prop="impdao">
                    <div style="display:flex;">
                        <el-input type="text" readonly="readonly" placeholder="请选择本地文件夹导入商品数据" v-model="uploadtext" style="width:40%;" show-word-limit>
                        </el-input>
                        <el-button type="primary" plain round style="margin-left:10px;" @click="addtialog=true">上传</el-button>

                    </div>
                </el-form-item>
            </el-form>
        </div>

        <el-dialog title="提示" :visible.sync="addtialog" width="30%">
            <div>
                <el-button type="text" @click='importTemplate'>下载模板</el-button>
                <p>导入时，如存在相同条码（款号）的商品资料，按如下处理：</p>
                <el-radio v-model="radiosel.edit" :label="0">跳过不处理</el-radio>
                <el-radio v-model="radiosel.edit" :label="1">数据覆盖</el-radio>
                <el-radio v-model="radiosel.edit" :label="2">先删除再新增</el-radio>
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="saveaddsel">确 定</el-button> -->

                <el-upload class="upload-demo" :headers="headers" :action="uploadImgUrl" name="upfile" :on-success="handleUploadSuccess" :data="radiosel" :before-upload="handleBeforeUpload" :on-error="handleUploadError" :show-file-list="false" accept='.xls,.xlsx'>
                    <el-button type="primary" style="margin-left:10px;">确定</el-button>
                </el-upload>

            </span>
        </el-dialog>

        <p class="disply"><span class="span"></span><span>商品类型</span></p>
        <div style="margin:30px 0;">

            <!-- ？？接口为单选，原型图为多选？？ -->
            <!-- <el-checkbox-group v-model="forDate.commtype" @change="handchange">
                <el-checkbox :label="10">实物类商品</el-checkbox>
                <el-checkbox :label="20">酒店</el-checkbox>
                <el-checkbox :label="21">门票</el-checkbox>
                <el-checkbox :label="22">充值话费</el-checkbox>
                <el-checkbox :label="23">充值流量</el-checkbox>
                <el-checkbox :label="24">充值油卡</el-checkbox>
            </el-checkbox-group> -->

            <el-radio-group v-model="ruleForm.product.type" :disabled='produId!=null'>
                <el-radio :label="10">实物类商品</el-radio>
                <el-radio :label="20">酒店</el-radio>
                <el-radio :label="21">门票</el-radio>
                <el-radio :label="22">充值话费</el-radio>
                <el-radio :label="23">充值流量</el-radio>
                <el-radio :label="24">充值油卡</el-radio>
            </el-radio-group>

        </div>
        <p class="disply"><span class="span"></span><span>存放仓库</span></p>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <div style="margin:30px 0;">
                <el-form-item label="选择仓库">
                    <el-select filterable v-model="ruleForm.product.warehouseId" placeholder="请选择">
                        <el-option v-for="item in optionsa" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <p class="disply"><span class="span"></span><span>基本信息</span></p>

            <el-form-item label="商品分类" required prop="product.pcate">
                <el-cascader filterable v-model="selclass" :options="productTcate" :props="{value:'id',label: 'name',}" @change="selproclass"></el-cascader>
                <el-link type="primary" class='a' @click="getCategoryList">刷新</el-link>
            </el-form-item>
            <el-form-item label="商品标题" prop="product.name">
                <el-input type="text" placeholder="请输入内容" v-model="ruleForm.product.name" maxlength="50" style="width:40%;" show-word-limit>
                </el-input>
            </el-form-item>
            <el-form-item label="供应商">
                <el-select filterable v-model="ruleForm.product.supplierId" placeholder="请选择">
                    <el-option v-for="item in optionsSupplier" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="品牌">
                <el-select filterable v-model="ruleForm.product.brandId" placeholder="请选择">
                    <el-option v-for="item in optionsp" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="商品编码" required prop="product.no">
                <el-input type="text1" placeholder="" v-model="ruleForm.product.no" style="width: 25%;" show-word-limit>
                </el-input>
                <el-button type="primary" style="margin-left: 20px;" @click='saveno'>生成</el-button>
            </el-form-item>
            <el-form-item label="商品条码">
                <el-input type="text1" placeholder="请输入条码" v-model="ruleForm.product.code" maxlength="16" style="width: 25%;" show-word-limit>
                </el-input>
            </el-form-item>
            <el-form-item label="成本价">
                <el-input type="text1" placeholder="请输入成本价" v-model="ruleForm.product.costPrice" maxlength="16" style="width: 25%;" show-word-limit>
                </el-input>
            </el-form-item>
            <el-form-item label="建议零售价">
                <el-input type="text1" placeholder="请输入原价" v-model="ruleForm.product.minPrice" maxlength="16" style="width: 25%;" show-word-limit>
                </el-input>
            </el-form-item>
            <el-form-item label="电商价">
                <el-input type="text1" placeholder="请输入电商价" v-model="ruleForm.product.sourcePrice" maxlength="16" style="width: 25%;" show-word-limit>
                </el-input>
            </el-form-item>
            <el-form-item label="市场价">
                <el-input type="text1" placeholder="请输入市场价" v-model="ruleForm.product.marketPrice" maxlength="16" style="width: 25%;" show-word-limit>
                </el-input>
            </el-form-item>
            <el-form-item label="商品库存" prop="product.stock">
                <el-input type="number" placeholder="请输入" :disabled="ruleForm.product.useSpec" v-model="ruleForm.product.stock" style="width:40%;" show-word-limit>
                </el-input>
            </el-form-item>
            <el-form-item label="商品描述">
                <el-input style="width: 50%;" type="textarea" v-model="ruleForm.product.description" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="商品主图">

                <Imglist :imgArr.sync='ruleForm.product.imgList'></Imglist>
                <span class="span_gray">支持批量传图，最多上传9张图片，拖拽图片可进行排序，单张图片需限制在1M以内。</span>

            </el-form-item>
            <p class="disply"><span class="span"></span><span>商品信息</span>
                <el-link type="primary" class='a'>预览</el-link>
            </p>

            <div style="width:100%;padding-right:10px;">
                <!-- <Tinymce v-model="ruleForm.product.tags"></Tinymce> -->
                <QuillEditor :detailContent.sync='ruleForm.product.detailContent'></QuillEditor>
            </div>

            <p class="disply" style="margin-top:100px;"><span class="span"></span><span>商品属性</span>

                <el-checkbox label="启用多规格" v-model="ruleForm.product.useSpec" size="mini" border style="margin-left: 10px;" @change="slesuespec"></el-checkbox>
            </p>
            <el-row>
                <el-col :span="9" style="background-color: #EEF1F6;margin:0 20px 20px 0;" v-for="(specKey, keyIndex) in form.spec" :key="keyIndex">
                    <el-form-item label="属性名称" :rules="{required: true,message: '属性名不能为空',trigger: 'blur'}">
                        <el-autocomplete v-model="specKey.name" :fetch-suggestions="querySearchAsync" placeholder="请输入内容">
                        </el-autocomplete>
                        <!--   <el-select
                      			v-model="specKey.name"
                                  placeholder="请选择"
                                  clearable
                                  filterable
                                  @blur="selectBlur"
                                  @clear="selectClear"
                                  @change="selectChange"
                                  >
                                <el-option
                                  v-for="(item,index) in specList"
                                  :key="id"
                                  :label="item.name"
                                  :value="item.name" />
                      </el-select> -->
                        <!--  <el-input type="text" placeholder="请输入" v-model="specKey.name" maxlength="16" style="width:40%;margin-left: 10px;" show-word-limit>
                        </el-input> -->
                        <el-link type="primary" class='a' style='margin-top: 10px;' :underline="false" @click="delspec(keyIndex)">删除属性</el-link>
                    </el-form-item>
                    <el-form-item class="up_d" v-for="(specValue,valueIndex) in specKey.specItems" :key="valueIndex" label="属性值">
                        <el-input type="text1" placeholder="请输入" v-model="specValue.name" maxlength="16" @input="changeSpecValueInput()" style="width:40%;margin-left: 10px;margin-top: 7px;" show-word-limit>
                        </el-input>

                        <SkuImgUploat @SkuSuccess='SkuSuccess' v-model="specValue.image" :skuimg.sync='specValue.image' v-if="keyIndex==0"></SkuImgUploat>

                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                        <el-button icon="el-icon-delete-solid" size="small" style="margin: 7px 2px; width: 35px; height: 35px;" @click="delspecitem(keyIndex,valueIndex)"></el-button>
                    </el-form-item>

                    <el-form-item>
                        <el-link type="primary" class='a' style='margin-top: 10px;' :underline="false" @click="addSpecValue(keyIndex)">+添加属性值</el-link>
                    </el-form-item>

                </el-col>
            </el-row>
            <el-link type="primary" class='a' style='margin-top: 10px;' @click="addSpecKey">添加创建属性</el-link>
            <p class="disply"><span class="span"></span><span>规格售价与库存</span></p>
            <el-form :model="form" ref="form" v-if="form.spec.length>0">

                <el-table @cell-click="cellClick" :data="form.skus" element-loading-text="数据加载中" stripe border ref="infoTable" style="margin-top:20px">

                    <el-table-column v-for="(specKey, keyIndex) in form.spec" :label="specKey.name" :key="keyIndex">
                        <template slot-scope="scope">
                            <div>
                                <!-- {{ scope.row.spec[keyIndex] }} -->
                                {{ scope.row.specItemNames[keyIndex] }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="costPrice" label="成本价" width="250" align="center">
                        <template slot="header" slot-scope="scope">
                            <span>成本价</span>

                            <!-- 批量弹窗 -->
                            <el-popover placement="top" width="400" trigger="click" v-model="showsupplyprice">
                                <el-form>
                                    <el-form-item>
                                        <el-input step="0.01" min="0" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="scope.column.costPrice" placeholder="请输入"></el-input>
                                    </el-form-item>
                                </el-form>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="text" @click="showsupplyprice = false">取消</el-button>
                                    <el-button type="primary" size="mini" @click="saveP(scope.column,scope.$index,'showsupplyprice')">确定</el-button>
                                </div>
                                <el-link type="primary" class='a' style='margin-left: 10px;' :underline="false" slot="reference">批量</el-link>
                            </el-popover>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item v-if="scope.row.seen">
                                <el-input @blur="loseFcous(scope.$index, scope.row)" size="small" v-model="scope.row.costPrice" placeholder="请输入价格"></el-input>
                            </el-form-item>
                            <span style="margin-left: 10px" v-else>{{ scope.row.costPrice }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="minPrice" label="建议零售价" width="250" align="center">
                        <template slot="header" slot-scope="scope">
                            <span>建议零售价</span>
                            <el-popover placement="top" width="400" trigger="click" v-model="showdistr">
                                <el-form>
                                    <el-form-item>
                                        <el-input step="0.01" min="0" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="scope.column.minPrice" placeholder="请输入"></el-input>
                                    </el-form-item>
                                </el-form>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="text" @click="showdistr = false">取消</el-button>
                                    <el-button type="primary" size="mini" @click="saveP(scope.column,scope.$index,'showdistr')">确定</el-button>
                                </div>
                                <el-link type="primary" class='a' style='margin-left: 10px;' :underline="false" slot="reference">批量</el-link>
                            </el-popover>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item v-if="scope.row.seen">
                                <el-input @blur="loseFcous(scope.$index, scope.row)" size="small" v-model="scope.row.minPrice" placeholder="请输入价格"></el-input>
                            </el-form-item>
                            <span style="margin-left: 10px" v-else>{{ scope.row.minPrice }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sourcePrice" label="电商价" width="250" align="center">
                        <template slot="header" slot-scope="scope">
                            <span>电商价</span>

                            <el-popover placement="top" width="400" trigger="click" v-model="showmarket">
                                <el-form>
                                    <el-form-item>
                                        <el-input step="0.01" min="0" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="scope.column.sourcePrice" placeholder="请输入"></el-input>
                                    </el-form-item>
                                </el-form>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="text" @click="showmarket = false">取消</el-button>
                                    <el-button type="primary" size="mini" @click="saveP(scope.column,scope.$index,'showmarket')">确定</el-button>
                                </div>
                                <el-link type="primary" class='a' style='margin-left: 10px;' :underline="false" slot="reference">批量</el-link>
                            </el-popover>

                        </template>
                        <template slot-scope="scope">
                            <el-form-item v-if="scope.row.seen">
                                <el-input @blur="loseFcous(scope.$index, scope.row)" size="small" v-model="scope.row.sourcePrice" placeholder="请输入价格"></el-input>
                            </el-form-item>
                            <span style="margin-left: 10px" v-else>{{ scope.row.sourcePrice }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="marketPrice" label="市场价" width="250" align="center">
                        <template slot="header" slot-scope="scope">
                            <span>市场价</span>

                            <el-popover placement="top" width="400" trigger="click" v-model="showmarketA">
                                <el-form>
                                    <el-form-item>
                                        <el-input step="0.01" min="0" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="scope.column.marketPrice" placeholder="请输入"></el-input>
                                    </el-form-item>
                                </el-form>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="text" @click="showmarketA = false">取消</el-button>
                                    <el-button type="primary" size="mini" @click="saveP(scope.column,scope.$index,'showmarketA')">确定</el-button>
                                </div>
                                <el-link type="primary" class='a' style='margin-left: 10px;' :underline="false" slot="reference">批量</el-link>
                            </el-popover>

                        </template>
                        <template slot-scope="scope">
                            <el-form-item v-if="scope.row.seen">
                                <el-input @blur="loseFcous(scope.$index, scope.row)" size="small" v-model="scope.row.marketPrice" placeholder="请输入价格"></el-input>
                            </el-form-item>
                            <span style="margin-left: 10px" v-else>{{ scope.row.marketPrice }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="no" label="SKU编码" width="250" align="center">
                        <template slot="header" slot-scope="scope">
                            <span>SKU编码</span>
                            <el-link type="primary" class='a' style='margin-left: 10px;' @click="saveP(scope.column,scope.$index,'showstock')">批量</el-link>
                            <!--  <el-popover placement="top" width="400" trigger="click" v-model="showstock">
                               <el-form>
                                    <el-form-item>
                                        <el-input step="0.01" min="0" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="scope.column.no" placeholder="请输入"></el-input>
                                    </el-form-item>
                                </el-form>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="text" @click="showstock = false">取消</el-button>
                                    <el-button type="primary" size="mini" @click="saveP(scope.column,scope.$index,'showstock')">确定</el-button>
                                </div>
                                <el-link type="primary" class='a' style='margin-left: 10px;' :underline="false" slot="reference">批量</el-link>
                            </el-popover> -->
                        </template>
                        <template slot-scope="scope">
                            <el-form-item v-if="scope.row.seen">
                                <el-input @blur="loseFcous(scope.$index, scope.row)" size="small" v-model="scope.row.no" placeholder="请输入数量"></el-input>
                            </el-form-item>
                            <span style="margin-left: 10px" v-else>{{ scope.row.no }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="code" label="条码" align="center">
                        <template slot-scope="scope">
                            <el-form-item v-if="scope.row.seen">
                                <el-input size="small" @blur="loseFcous(scope.$index, scope.row)" v-model="scope.row.code" placeholder="请输入商品条码"></el-input>
                            </el-form-item>
                            <span style="margin-left: 10px" v-else>{{ scope.row.code }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="stock" label="库存" align="center">
                        <template slot-scope="scope">
                            <el-form-item v-if="scope.row.seen">
                                <el-input size="small" type="number" min="0" @blur="loseFcous(scope.$index, scope.row)" v-model="scope.row.stock" placeholder="请输入商品条码"></el-input>
                            </el-form-item>
                            <span style="margin-left: 10px" v-else>{{ scope.row.stock }}</span>
                        </template>
                    </el-table-column>

                </el-table>
            </el-form>

            <div class="btnA">
                <el-button size="small" @click="back">取消</el-button>
                <el-button type="primary" size="small" @click="submit('ruleForm')">{{produId==null?'发布':'保存'}}</el-button>
            </div>
        </el-form>

        <!-- 选择图片对话框 -->
        <!-- <Picture :ImgVisible="seleimage" @toggleImg="clostate" @getImg="getImage"></Picture> -->

    </div>

</template>

<script>
// import { getToken } from "@/utils/auth";
import Imglist from '@/components/Imglist';
import QuillEditor from '@/components/quillEditor'
import SkuImgUploat from '@/components/Skuimgupload'
import { getCode, ProduAddandEdit, Detail, grapLinks, getspec } from '@/api/product/product';
import { getPag } from '@/api/product/brand';
import { getPagWarehouse } from '@/api/product/warehouse';
import { SupplierList } from '@/api/supplier/list.js';
import { CategoryList } from '@/api/product/classification';
export default {
    name: 'Handwork',
    components: {
        Imglist,
        // Picture,
        // Tinymce,
        QuillEditor,
        SkuImgUploat
    },
    data() {
        return {
            specList: [],//属性列表
            showsupplyprice: false, //批量弹窗，成本价
            fullloading: false, //加载中弹窗（全局，上传文件用）
            showdistr: false, //批量弹窗，售卖价
            showmarket: false, //批量弹窗，电商价
            showmarketA: false,//批量弹窗，市场价
            showstock: false, //批量弹窗，库存
            uploadtext: '', //本地文件上传名称
            popvisible: false, //批量修改弹窗
            dataErp: true, //资料同步ERP

            seleimage: false, //控制选择图片对话框

            produId: null,//编辑时商品的id

            selclass: [],//选择的分类

            check: true,

            form: {
                spec: [], //规格名信息
                option: [], //规格信息
                description: null, //详情
                skus: [],//sku列表
            },


            Babylink: '', //宝贝链接
            files: [],
            selradio: 0,
            text: '',
            text1: '',
            //商品分类
            productTcate: [],


            ruleForm: {//表单数据

                product: {//商品
                    sourcePlatformId: 0, // 发布方式(来源)
                    type: null, //商品类型
                    name: '', //商品标题
                    stock: null,//库存

                    pcate: null,//一级分类id
                    cate: null,//二级分类id
                    tcate: null,//三级分类id
                    supplierId: null,//供应商
                    description: '', //商品描述
                    code: '', //商品条码
                    no: '', //商品编码
                    brandId: null, // 品牌id
                    warehouseId: null, //仓库id
                    imgList: [],//商品主图(图片列表);
                    detailContent: '',//商品信息
                    useSpec: false,//是否启用多规格
                    costPrice: null,//成本价
                    minPrice: null,//建议零售价
                    sourcePrice: null,//电商价
                    marketPrice: null,//市场价
                },

                skus: [{//sku列表
                    name: null,//商品名称（格式：颜色-红,尺寸-M）
                    costPrice: null,//成本
                    minPrice: null,//最低售价
                    sourcePrice: null,//电商价
                    no: null,//编码
                    code: null,//条码
                }],
                specs: [{//属性键值
                    id: null,//属性id
                    name: null,//属性名称
                    specItems: [{
                        id: null,//属性值id
                        image: null,//属性值图片
                        name: null,//属性值名称
                    }],
                }],
            },

            dialogImageUrl: '',
            dialogVisible: false,
            optionsa: [],//仓库列表
            optionsSupplier: [],//供应商列表
            optionsp: [],//品牌列表

            rules: {
                "product.name": [{
                    required: true,
                    message: '请输入商品标题',
                    trigger: 'change'
                }],
                'product.pcate': [{
                    required: true,
                    message: '请选择商品分类',
                    trigger: 'change'
                }],
                "product.no": [{
                    required: true,
                    message: '商品编码不能为空',
                    trigger: 'change'
                }],
            },

            uploadImgUrl: process.env.VUE_APP_BASE_API + "/product/product/exportInsertProduct", // 导入商品表格
            headers: {
                // Authorization: getToken(),
            },

            radiosel: {//导入商品数据处理方式
                edit: 0,
            },

            addtialog: false,//导入商品数据处理弹窗


        };
    },

    created() {
        // console.log(this.$route.query.id);

        // 商品id，新增时会返回undefined
        this.produId = this.$route.query.id;
        this.init();
    },

    mounted() {

    },
    methods: {
        init() {
            this.getBrandList();
            this.getSupplierList();
            this.getCategoryList();
        },

        // 抓取链接
        grablink() {

            if (this.Babylink == '') {
                this.$message.warning('请输入宝贝链接');
                return false;
            }

            let data = {
                url: this.Babylink,
                cookie: getToken(),
            }

            grapLinks(data).then(res => {
                if (res.code) {
                    console.log(res);
                }
            })

        },

        // 查看商品详情
        getpordetal() {

            Detail({ id: this.produId }).then(res => {
                if (res.code == 0) {
                    // console.log(res)

                    let skus = res.data.skus;

                    let specs = res.data.specs;

                    this.ruleForm.product = res.data.product;

                    this.ruleForm.product.imgList = JSON.parse(this.ruleForm.product.imgList)

                    this.ruleForm.product.sourcePlatformId = res.data.product.sourceChannelId;

                    let selarr = [];
                    if (res.data.product.pcate > 0) {
                        selarr.push(res.data.product.pcate);
                    }
                    if (res.data.product.cate > 0) {
                        selarr.push(res.data.product.cate);
                    }
                    if (res.data.product.tcate > 0) {
                        selarr.push(res.data.product.tcate);
                    }

                    this.selclass = selarr;

                    specs == null ? this.form.spec = [] : this.form.spec = specs;

                    skus == null ? this.form.skus = [] : this.form.skus = skus;


                    this.updateOption(1); //更新列表

                }
            })


        },


        //生成商品编码按钮
        saveno() {

            if (this.ruleForm.product.type == null) {
                this.$message.warning('请先选择商品类型');
                return false;
            }

            getCode({ type: this.ruleForm.product.type }).then(res => {
                if (res.code == 0) {
                    // console.log(res)
                    this.ruleForm.product.no = res.msg;
                    this.$message({
                        type: "success",
                        message: "生成商品编码成功",
                    });

                }

            })
        },


        // 选择的商品分类
        selproclass(val) {

            // console.log(this.selclass)

            this.ruleForm.product.pcate = val[0];
            this.ruleForm.product.cate = val[1];
            this.ruleForm.product.tcate = val[2];
        },

        //品牌
        getBrandList() {
            getPag({}).then(res => {
                // console.log('品牌',res)
                this.optionsp = res.data
            })
        },

        // 仓库
        getSupplierList() {
            getPagWarehouse().then(res => {
                this.optionsa = res.data;
            })
            //获取供应商列表
            SupplierList().then(res => {
                this.optionsSupplier = res.data;
            })
        },

        //属性值
        querySearchAsync(queryString, callback) {
            //获取列表
            getspec({}).then(res => {
                //去重
                var result = [];
                var obj = {};
                for (var i = 0; i < res.data.length; i++) {
                    if (!obj[res.data[i].name]) {
                        result.push(res.data[i]);
                        obj[res.data[i].name] = res.data[i].name;
                    }
                }
                //必须返回value的字段名称
                var person = result.map((x) => { return { "value": x.name } })
                callback(person);
            })
        },

        selectBlur(e) {
            // 意见类型
            if (e.target.value !== '') {
                this.specKey.name = e.target.value;
                this.$forceUpdate()   // 强制更新
            }
        },
        selectClear() {
            this.specKey.name = ''
            this.$forceUpdate()
        },
        selectChange(val) {
            this.specKey.name = val
            this.$forceUpdate()
        },
        // 产品分类
        getCategoryList() {
            CategoryList().then(res => {
                let data = res.data;
                data.forEach(item => {
                    if (item.children.length > 0) {
                        item.children.forEach(val => {
                            if (val.children.length > 0) {
                                val.children.forEach(emt => {
                                    delete emt.children;
                                })
                            } else {
                                delete val.children;
                            }
                        })
                    } else {
                        delete item.children;
                    }
                })
                this.productTcate = data;


                if (this.produId) {
                    this.getpordetal();
                }

            })
        },

        // 是否启用多规格
        slesuespec(val) {

            // console.log(val)

            if (!val) {
                this.form.spec = [];
                this.form.skus = [];
            }

        },

        // 取消
        back() {
            // console.log(this.$route.path)
            // 卸载当前页面
            this.$store.state.tagsView.visitedViews.splice(this.$store.state.tagsView.visitedViews.findIndex(item =>
                item.path === this.$route.path), 1)
            // this.$router.go(-1)
            this.$router.back();
            // this.$router.push(this.$store.state.tagsView.visitedViews[this.$store.state.tagsView.visitedViews.length -
            // 	1].path)
        },

        // 导入本地商品数据
        handleUploadSuccess(res) { //上传成功
            console.log(res)
            this.fullloading.close();
            // 文件名称（此处没有，需根据真实接口来）
            this.uploadtext = res.id;
            this.$message.success('上传成功');
            this.back();
        },
        handleBeforeUpload() { //上传前


            this.fullloading = this.$loading({
                lock: true,
                text: "上传中",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },
        handleUploadError() {
            this.$message({
                type: "error",
                message: "上传失败",
            });
            this.fullloading.close();
        },

        // 点击输入每行表格数据
        cellClick(row) {
            this.$set(row, 'seen', true);
        },

        // 批量确认按钮
        saveP(column, index, val) {
            let str = column.property;
            let _this = this;
            if (val == 'showstock') {
                if (this.ruleForm.product.no == '') {
                    this.$message.warning('请先生成商品编码');
                    return false;
                }
                this.form.skus.map((item, index) =>

                    _this.$set(item, str, this.ruleForm.product.no + '-' + (index + 1))
                )
            } else {
                this.form.skus.map((item, index) =>

                    _this.$set(item, str, column[str])
                )

            }
            this[val] = false;
            // console.log(column, index, val, 'qqqqqqqqqqq')



            // console.log(column[str], '输入的');
            // console.log(this.ruleForm.product.no)

            // column[str]=this.ruleForm.product.no+'-'+






        },

        // 好像没啥用
        loseFcous() { },


        // 添加创建属性
        addSpecKey() {

            if (!this.ruleForm.product.useSpec) {

                this.$message.warning('请先启用多规格');

                return false;
            }

            this.form.spec.push({
                id: null,
                name: "",
                specItems: [],
            })
        },

        // 添加规格值
        addSpecValue(index) {
            this.form.spec[index].specItems.push({
                id: null,
                name: '',
                image: '',
            })


            this.updateOption(); //更新列表
        },

        // 表格更新联动
        updateOption(type = 0) {
            let list = [];
            this.form.spec.forEach(item => { //属性、规格重组
                let value = [];
                item.specItems.forEach(elemt => {
                    value.push(`${item.id}-${elemt.id}|${item.name}-${elemt.name}`)
                })

                list.push(value);
            })

            let ret = this.descartes(list);

            // console.log(ret, 'ret')

            let option = [];
            for (let i = 0; i < ret.length; i++) {
                let specArr = []
                let specs = ''
                let name = ''
                let dArr = ret[i]
                for (let j = 0; j < dArr.length; j++) {
                    let dItem = dArr[j]
                    let kvArr = dItem.split('|')
                    specArr.push(kvArr[1].split('-')[1])
                    specs += kvArr[0] + (j !== dArr.length - 1 ? ',' : '')
                    name += kvArr[1] + (j !== dArr.length - 1 ? ',' : '')
                }

                if (type == 1) {//编辑，第一次渲染sku表格
                    var item = {
                        costPrice: this.form.skus[i].costPrice,//成本价
                        minPrice: this.form.skus[i].minPrice,//建议零售价
                        sourcePrice: this.form.skus[i].sourcePrice,//电商价
                        marketPrice: this.form.skus[i].marketPrice,//市场价
                        no: this.form.skus[i].no,//编码
                        code: this.form.skus[i].code,//条码
                        stock: this.form.skus[i].stock,//库存
                        name: name,//名称
                        specItemNames: specArr,
                    }
                } else {
                    var item = {
                        costPrice: null,//成本价
                        minPrice: null,//建议零售价
                        sourcePrice: null,//电商价
                        marketPrice: null,//市场价
                        no: null,//编码
                        code: '',//条码
                        stock: 0,//库存
                        name: name,//名称
                        specItemNames: specArr,
                        // specs: specs,
                    }
                }

                for (let oldOption of this.form.skus) {
                    let eq = true
                    for (let s of item.specItemNames) {
                        if (oldOption.specItemNames.indexOf(s) < 0) {
                            eq = false
                            break
                        }
                    }

                    if (eq) {

                        item.marketPrice = oldOption.marketPrice//建议零售价
                        item.minPrice = oldOption.minPrice//建议零售价
                        item.sourcePrice = oldOption.sourcePrice//电商价
                        item.costPrice = oldOption.costPrice//成本价
                        item.no = oldOption.no//编码
                        item.code = oldOption.code//条码
                        item.stock = oldOption.stock//库存

                        item.name = oldOption.name;//属性规格名


                        // item.specs = oldOption.specs//ID相对

                        // item.id = oldOption.id
                        // item.price = oldOption.price
                        // item.productprice = oldOption.productprice
                        // item.productsn = oldOption.productsn
                        // item.r = oldOption.r
                        // item.stock_lock = oldOption.stock_lock
                        // item.thumb = oldOption.thumb
                        // item.weight = oldOption.weight
                    }
                }
                option.push(item)
            }
            this.form.skus = option;




        },

        // 笛卡尔积算法(计算SKU规格信息)
        descartes(list) {
            // parent上一级索引;count指针计数
            var point = {}
            var result = []
            var pIndex = null
            var tempCount = 0
            var temp = []
            // 根据参数列生成指针对象
            for (let index in list) {
                if (typeof list[index] === 'object') {
                    point[index] = {
                        'parent': pIndex,
                        'count': 0
                    }
                    pIndex = index
                }
            }
            // 单维度数据结构直接返回
            if (pIndex == null) {
                return list
            }
            // 动态生成笛卡尔积
            while (true) {
                for (var index in list) {
                    tempCount = point[index]['count']
                    temp.push(list[index][tempCount])
                }
                // 压入结果数组
                result.push(temp)
                temp = []
                // 检查指针最大值问题
                while (true) {
                    if (point[index]['count'] + 1 >= list[index].length) {
                        point[index]['count'] = 0
                        pIndex = point[index]['parent']
                        if (pIndex == null) {
                            return result
                        }
                        // 赋值parent进行再次检查
                        index = pIndex
                    } else {
                        point[index]['count']++
                        break
                    }
                }
            }
        },

        // 属性值内容改变，刷新值
        changeSpecValueInput() {
            this.updateOption();
        },

        // 删除属性
        delspec(index) {
            this.form.spec.splice(index, 1);
            this.updateOption();
        },

        // 删除属性值
        delspecitem(speindex, spe_itemindex) {
            // 当属性值删除只剩一个时，为避免报错只清空值不做删除操作
            if (this.form.spec[speindex].specItems.length == 1) {
                this.form.spec[speindex].specItems = [{
                    id: null,
                    name: '',
                    image: '',
                }]
            } else {
                this.form.spec[speindex].specItems.splice(spe_itemindex, 1);
            }
            this.updateOption();
        },

        // 表单提交（发布）
        submit(formName) {

            let product = JSON.parse(JSON.stringify(this.ruleForm.product));
            product.imgList = JSON.stringify(product.imgList)

            let Skusdata = this.form.skus;
            let specs = this.form.spec;
            let skus = [];



            if (Skusdata && Skusdata.length > 0) {
                Skusdata.forEach(item => {
                    let skuitem = {
                        name: item.name,//规格商品名
                        costPrice: item.costPrice,//成本
                        minPrice: item.minPrice,//建议零售价售价
                        sourcePrice: item.sourcePrice,//电商价
                        marketPrice: item.marketPrice,//市场价
                        no: item.no,//编码
                        code: item.code,//条码
                        stock: item.stock,//库存
                    };
                    skus.push(skuitem);
                })
            }
            let data = {};
            data.product = product;
            data.specs = specs;
            data.skus = skus;

            if (this.produId) {//如果有商品id，则赋值上id，则为商品编辑
                data.product.id = this.produId;
            }

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 编辑和新增都是同一个接口
                    ProduAddandEdit(data).then(res => {
                        if (res.code == 0) {
                            if (this.produId) {
                                this.$message({
                                    type: "success",
                                    message: "编辑成功",
                                });
                            } else {
                                this.$message({
                                    type: "success",
                                    message: "发布成功",
                                });
                            }
                            this.$router.push({ path: '/product-list' })
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        /** 下载模板操作 */
        importTemplate() {
            this.downloadget('system/file/downModel', { fileName: '商品导入模板.xlsx' }, `商品导入模板.xlsx`).then(res => {

            })
        },


        // sku图片上传成功回调
        SkuSuccess() {

        },

    }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.handwork {
    margin: 10px 20px 0px 20px;
    background-color: #fff;
    padding: 25px;

    .disply {
        display: flex;
        align-items: center;

        P {
            margin: 0px;
            font-size: 14px;
        }
    }

    .span {
        display: block;
        width: 3px;
        height: 15px;
        background-color: #1890ff;
        // margin-top: 4px;
        margin-right: 5px;
    }

    .a {
        margin-left: 10px;
    }

    .el-upload--picture-card:nth-child(1) {
        width: 85px !important;
        height: 85px !important;
        line-height: 85px !important;
    }

    #up {
        padding: 7px 3px;
        height: 35px;

        .el-upload--picture-card {
            border: 1px dashed #13c3c7;
            height: 35px !important;
        }

        .el-upload--picture-card:hover {
            border-color: #13c3c7;
            color: #13c3c7;
        }

        .el-upload--picture-card {
            width: 35px;
            height: 35px;
            font-size: 14px !important;
        }

        .el-upload {
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 14px;
        }

        .el-upload-list--picture-card .el-upload-list__item {
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 14px;
        }

        .el-upload-list--picture-card .el-upload-list__item-actions:hover {
            opacity: 1;
            font-size: 14px;
        }

        .el-upload-list--picture-card .el-upload-list__item-thumbnail {
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 14px;
        }

        .avatar {
            width: 35px;
            height: 35px;
        }
    }

    .input {
        width: 20%;
        margin-left: 10px;
        margin-top: 7px;
    }

    // #up_sice{
    //   width: 87px;
    //   height: 87px;
    //   .el-upload--picture-card{
    //     width: 100% !important;
    //     height: 100% !important;
    //     line-height: 87px !important;
    //   }

    // }
    .up_d {
        & ::v-deep .el-form-item__content {
            display: flex !important;
            align-items: center;
            #up {
                display: flex !important;
                align-items: center;
            }

            // .el-upload-list__item-actions {
            //     width: 35px;
            //     height: 35px;
            // }
        }

        & ::v-deep .el-upload-list__item {
            width: 35px !important;
            height: 35px !important;
            margin: 0 6px 0px 5px;
        }
        & ::v-deep .el-upload--picture-card {
            width: 35px !important;
            height: 35px !important;
            line-height: 45px;
        }

        & ::v-deep .el-upload-list--picture-card {
            display: flex;
            align-items: center;
        }
    }

    .asd {
        justify-content: space-around;
        // padding-left: 5%;
        width: 20%;

        p {
            width: 20%;
        }
    }

    .btnA {
        width: 240px;
        margin: 10px auto;
    }

    .el-button--small {
        padding: 9px 10px !important;
    }
}
</style>
