import {request,} from '@/common/requestZt.js'
import {ztUrl} from '@/common/const.js'
/* 仓库  */

let url = ztUrl.replace('/admin', '')

// 仓库列表 无分页
export function getPagWarehouse(data) {
    return request.get(url + '/product/warehouse/list',
        data, localStorage.getItem('middle-token'))
}
